import React from "react";
import get from "lodash/get";

import { graphql } from "gatsby";

import Layout from "../components/global/layout";
import Container from "../components/global/container";
import InnerPageHeader from "../components/inner-page-header/inner-page-header";

class BugBounty extends React.Component {
    render() {
        const meta = get(this, "props.data.meta");
        const { location } = this.props;

        return (
            <Layout
                title={`Bug Bounty | ${meta.siteMetadata.title}`}
                location={location}
            >
                <InnerPageHeader pageTitle="Bug Bounty" />
                <article>
                    <section className="py-60 background-black text-white">
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <p>
                                        Welcome to our Bug Bounty Program. We
                                        want Unifyd protocol to be the best it can
                                        be, so we’re calling on our community to
                                        help us find any bugs or
                                        vulnerabilities. Submit a bug here and
                                        earn a reward of up to USD 250,000$.
                                        Please see our Rules & Rewards section
                                        for more details.
                                    </p>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md">
                                        Rules
                                    </h2>
                                    <br />
                                    <ul className="mb-20">
                                        <li className="mb-10">
                                            Public disclosure of a vulnerability
                                            would make it ineligible for a
                                            reward.
                                        </li>
                                        <li className="mb-10">
                                            Technical knowledge is required for
                                            the process.
                                        </li>
                                        <li className="mb-10">
                                            Duplicated issues are not eligible
                                            for reward. The first submission
                                            would be the eligible one.
                                        </li>
                                        <li className="mb-10">
                                            If you want to add more information
                                            to a provided issue, create a new
                                            submission giving reference to the
                                            initial one.
                                        </li>
                                        <li className="mb-10">
                                            Rewards will be decided on a case by
                                            case basis and the bug bounty
                                            program, terms, and conditions are
                                            at the sole discretion of Unifyd.
                                        </li>
                                        <li className="mb-10">
                                            Rewards will vary depending on the
                                            severity of the issue. Other
                                            variables considered for rewards
                                            include: the quality of the issue
                                            description, the instructions for
                                            reproducibility, and the quality of
                                            the fix (if included).
                                        </li>
                                        <li className="mb-10">
                                            Determinations of eligibility, score
                                            and all terms related to an award
                                            are at the sole and final discretion
                                            of Unifyd.
                                        </li>
                                        <li className="mb-10">
                                            Submissions needs to be related with
                                            the Bounty Scope. Submissions out of
                                            the Bounty Scope won’t be eligible
                                            for a reward.
                                        </li>
                                        <li className="mb-10">
                                            Any interference with the protocol,
                                            client or platform services, on
                                            purpose or not during the process
                                            will make the submission process
                                            unvalid.
                                        </li>
                                        <li className="mb-10">
                                            Terms and conditions of the bug
                                            bounty process may vary over time.
                                        </li>
                                        <li className="mb-10">
                                            Our bug bounty follows a similar
                                            approach as Ethereum Bug Bounty. The
                                            severity of the issues will be based
                                            according to the OWASP risk rating
                                            model based on Impact and
                                            Likelihood.
                                        </li>
                                        <li className="mb-10">
                                            It is mandatory to read and follow
                                            the responsible disclosure policy
                                            available in the references.
                                            Submissions not following the
                                            disclosure policy will not be
                                            elegible for a reward.
                                        </li>
                                    </ul>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md">
                                        Rewards
                                    </h2>
                                    <br />
                                    <table cellspacing="0">
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 20px 0",
                                                    }}
                                                >
                                                    Almost certain
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(148, 196, 125)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 1,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(255, 217, 102)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 5,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(241, 194, 51)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 10,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(255, 163, 36)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 50,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(255, 2, 2)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 250,000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 20px 0",
                                                    }}
                                                >
                                                    Likely
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(105, 169, 79)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 500
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(148, 196, 125)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 1,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(255, 217, 102)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 5,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(241, 194, 51)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 10,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(255, 163, 36)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 50,000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 20px 0",
                                                    }}
                                                >
                                                    Possible
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(53, 108, 25)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 100
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(105, 169, 79)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 500
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(148, 196, 125)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 1,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(255, 217, 102)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 5,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(241, 194, 51)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 10,000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 20px 0",
                                                    }}
                                                >
                                                    Unlikely
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(53, 108, 25)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 100
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(53, 108, 25)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 100
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(105, 169, 79)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 500
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(148, 196, 125)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 1,000
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(255, 217, 102)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 5,000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 20px 0",
                                                    }}
                                                >
                                                    Almost possible
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(53, 108, 25)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 100
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(53, 108, 25)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 100
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(53, 108, 25)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 100
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(105, 169, 79)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 500
                                                </td>
                                                <td
                                                    style={{
                                                        color: "rgb(148, 196, 125)",
                                                        padding: "20px",
                                                    }}
                                                >
                                                    $ 1,000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 0 20px",
                                                    }}
                                                ></td>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 0 20px",
                                                    }}
                                                >
                                                    Very low
                                                </td>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 0 20px",
                                                    }}
                                                >
                                                    Low
                                                </td>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 0 20px",
                                                    }}
                                                >
                                                    Moderate
                                                </td>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 0 20px",
                                                    }}
                                                >
                                                    High
                                                </td>
                                                <td
                                                    style={{
                                                        padding:
                                                            "20px 20px 0 20px",
                                                    }}
                                                >
                                                    Severe
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md mb-10">
                                        Vulnerabilities Classification
                                    </h2>
                                    <br />
                                    <p className="mb-20">
                                        <strong>Critical:</strong> An issue that
                                        might cause immediate loss of {">"} 10%
                                        of the funds, or permanent impairment of
                                        the protocol state.
                                    </p>
                                    <p className="mb-20">
                                        <strong>Very High / High:</strong> An
                                        issue that might cause immediate loss of{" "}
                                        {"<"} 10% of the funds, or severely
                                        damage the protocol state.
                                    </p>
                                    <p className="mb-20">
                                        <strong>Medium:</strong> An issue that
                                        might theoretically cause minimal loss
                                        of funds, damage the protocol state, or
                                        cause severe user dissatisfaction.
                                    </p>
                                    <p>
                                        <strong>Low / Very Low / Note:</strong>{" "}
                                        An issue that might cause user
                                        dissatisfaction or minimal failure.
                                    </p>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md mb-10">
                                        Exclusions
                                    </h2>
                                    <br />
                                    <p>
                                        While researching, we’d like to ask you
                                        to refrain from:
                                    </p>
                                    <ul>
                                        <li className="mb-10">
                                            Denial of service
                                        </li>
                                        <li className="mb-10">Spamming</li>
                                        <li className="mb-10">
                                            Social engineering (including
                                            phishing) of Unifyd staff or
                                            contractors
                                        </li>
                                        <li>
                                            Any physical attempts against Unifyd
                                            property or data centers
                                        </li>
                                    </ul>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md mb-10">
                                        Safe Harbor
                                    </h2>
                                    <br />
                                    <p>
                                        Any activities conducted in a manner
                                        consistent with this policy will be
                                        considered authorized conduct and we
                                        will not initiate legal action against
                                        you. If legal action is initiated by a
                                        third party against you in connection
                                        with activities conducted under this
                                        policy, we will take steps to make it
                                        known that your actions were conducted
                                        in compliance with this policy.
                                    </p>

                                    <hr className="my-60" />

                                    <h2 className="font-weight-bold font-size-md mb-10">
                                        Submit a bug
                                    </h2>
                                    <br />
                                    <p>
                                        Please report the bug you found via this
                                        email address:{" "}
                                        <a
                                            href="mailto: report@unifyd.finance"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            report@unifyd.finance
                                        </a>
                                        . Try to be as specific and clear as
                                        possible when you fill out this form. We
                                        will be in touch as soon as possible
                                        after receiving the form.
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </section>
                </article>
            </Layout>
        );
    }
}

export default BugBounty;

export const BugBountyQuery = graphql`
    query BugBounty {
        meta: site {
            siteMetadata {
                brandName
                description
                fullBrandName
                title
                siteUrl
            }
        }
    }
`;
